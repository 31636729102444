import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import AgendaCalendario from "../../componentsv2/AgendaCalendario";

import { ModalAviso } from "../../componentsv2/ModalAviso";

export default function Respiratorio() {
  const exames = ["Frutose", "Lactose", "SIBO"];
  //const horarios = ["06:30", "08:30"];
  const novosHorarios = ["06:30", "07:00", "08:30"];
  const diasSemana = [1, 2, 3, 4, 5, 6];
  //const novosDiasSemana = [1, 3, 5, 6];
  const acesso = "RES";

  const [openModalAlerta, setOpenModalAlerta] = useState(false);
  useEffect(() => {
    setOpenModalAlerta(true);
  }, []);

  return (
    <>
      <Header titulo="Exame Respiratório" />
      <ModalAviso
            modalText={
              "OBRIGATÓRIO ENVIO DAS RECOMENDAÇÕES/CARDÁPIO PARA REALIZAR O TESTE."
            }
            open={openModalAlerta}
            onClose={setOpenModalAlerta}
          />
      <AgendaCalendario
        agenda="respiratorio"
        unidade={42}
        exames={exames}
        horarios={novosHorarios}
        diasSemana={diasSemana}
        acesso={acesso}
      />
    </>
  );
}
