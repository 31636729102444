import React, { createContext, useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { api } from "../../../services/Api";

const FormularioContext = createContext();

function FormularioContextProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const dataAtual = new Date();

  const [unidade, setUnidade] = useState('');
  const [agenda, setAgenda] = useState('');
  const [agendamentos, setAgendamentos] = useState([]);
  const [dadosAgendamento, setDadosAgendamento] = useState([]);
  const [dia, setDia] = useState('');
  const [mes, setMes] = useState(dataAtual.getMonth());
  const [ano, setAno] = useState(dataAtual.getFullYear());
  const [final, setFinal] = useState('');
  const [referencia, setReferencia] = useState('');
  const [titulo, setTitulo] = useState('Agendamento');

  const [ abreAgendamento, setAbreAgendamento ] = useState(false);
  const [ atualizaTabela, setAtualizaTabela ] = useState(false);
  const [ horaSelecionada, setHoraSelecionada ] = useState(false);

  useEffect(()=>{
    if (final) {
      setReferencia(`${ano}-${mes+1}-${final}`)
    }
  },[ano, mes, final])

  useEffect(() => {
    (async () => {
      try {
        if (agenda && unidade && referencia) {
          const { data: results } = await api.get(`/v2/agendaRespiratorio/agendamentos/${agenda}/${unidade}/${referencia}`);
          // AJustar no próximo sprint
          // const grupos = [];
          // results.map((g) => {
          //   const grupoDia = [];
          //   results.forEach((o) => {
          //     if(o.data === g.data){
          //       grupoDia.push(o)
          //     }
          //   })
          //   grupos.push(grupoDia)
          //   return null
          // })
          setAgendamentos(results);
        }
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao carregar a tabela de cagendamentos. Tente novamente.",
          {
            variant: "error"
          }
        );
      }
    })();
  }, [enqueueSnackbar, agenda, unidade, referencia, final, atualizaTabela]);

  return (
    <FormularioContext.Provider
      value={{
        agendamentos,
        setAgendamentos,
        dadosAgendamento,
        setDadosAgendamento,
        dia,
        setDia,
        mes,
        setMes,
        ano,
        setAno,
        final,
        setFinal,
        titulo,
        setTitulo,
        abreAgendamento,
        setAbreAgendamento,
        atualizaTabela,
        setAtualizaTabela,
        horaSelecionada,
        setHoraSelecionada,
        setUnidade,
        setAgenda
      }}
    >
      {children}
    </FormularioContext.Provider>
  );
}

export { FormularioContext, FormularioContextProvider };
