import React, { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Context as AuthContext } from "./Context/AuthContext";

import Loading from "./components/Loading";

import Agendamento from "./pages/Agendamento";
import AgendaPaternidade from "./pages/AgendaPaternidade";
import AgendaGH from "./pages/GH";
import Almoxarifado from "./pages/Almoxarifado";
import CadastrarProduto from "./pages/Almoxarifado/CadastrarProduto";
import AdicionarEstoque from "./pages/Almoxarifado/Estoque/AdicionarEstoque";
import ProdutoInfo from "./pages/Almoxarifado/Estoque/ProdutoInfo";
import ExibeProdutos from "./pages/Almoxarifado/ExibirProdutos";
import CriarTransferencia from "./pages/Almoxarifado/Transferencias/CriaTransferencia";
import EditaTransferencia from "./pages/Almoxarifado/Transferencias/EditaTransferencia";
import Amostras from "./pages/Amostras";
import AtualizacoesApp from "./pages/AtualizacoesApp";
import CadastrarCategoria from "./pages/CadastraProduto";
import CadastraUsuarioIndex from "./pages/CadastraUsuario";
import ColetaDomiciliar from "./pages/ColetaDomiciliar";
import ConexaoDNA from "./pages/ConexaoDNA";
import ControleAcesso from "./pages/ControleAcesso";
import DriveThru from "./pages/DriveThru";
import Espermograma from "./pages/Espermograma";
import Faturamento from "./pages/Faturamento";
import CadastroFormulario from "./pages/FormularioCovid/Cadastro";
import ControleFormulario from "./pages/FormularioCovid/Controle";
import RespostasFormulario from "./pages/FormularioCovid/Respostas";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MapaCurvas from "./pages/MapaCurvas";
import MapaCurvasTv from "./pages/MapaCurvas/PaginaTv";
import MensagensApp from "./pages/MensagensApp";
// import NotificacoesCovid from "./pages/Administrativo/pages/NotificacoesCovid";
import Perfil from "./pages/Perfil";
import PortalMedico from "./pages/PortalMedico";
import EditorArtigoPortalMedico from "./pages/PortalMedico/pages/Editor/EditorArtigo";
import EditorPostPortalMedico from "./pages/PortalMedico/pages/Editor/EditorPost";
import PrecosExames from "./pages/PrecosExames";
import Respiratorio from "./pages/Respiratorio";

import FormContrato from "./pages/GestaoDeContratos/Contratos/FormContrato";
import FormFornecedor from "./pages/GestaoDeContratos/Fornecedores/FormFornecedor";
import Fornecedores from "./pages/GestaoDeContratos/Fornecedores";
import RotasAdministrativo from "./pages/Administrativo/routes";
import CadastrarBem from "./pages/Inventario/pages/Bem/CadastrarBem";
import CriaTransferencia from "./pages/Inventario/pages/Bem/Transferencia/CriaTransferencia";
import CriaInventario from "./pages/Inventario/pages/Inventarios/CriaInventario";
import Inventario from "./pages/Inventario";
import InventarioBem from "./pages/Inventario/pages/Bem/InventarioBem";

import ProcurarExames from "./pages/ProcurarExames";
import EditaInventario from "./pages/Inventario/pages/Inventarios/EditaInventario";

import AtendimentoAoCliente from "./pages/AtendimentoAoCliente";
import AdicionarOuvidoria from "./pages/AtendimentoAoCliente/pages/Ouvidoria/pages/Formulario";
import AdicionarSac from "./pages/AtendimentoAoCliente/pages/Sac/pages/Formulario";

import CentralDeBIs from "./pages/CentralDeBIs";
import BI from "./pages/CentralDeBIs/pages/BI";

import AgendaCare from "./pages/AgendaCare";
import FormularioAgendamento from "./pages/AgendaCare/pages/FormularioAgendamento";
import EditarAgendamento from "./pages/AgendaCare/pages/EditarAgendamento";
import VisualizarAgendamento from "./pages/AgendaCare/pages/VisualizarAgendamento";

import GestaoDeClientes from "./pages/GestaoDeClientes";
import CadastroDeClientes from "./pages/GestaoDeClientes/pages/CadastroDeClientes";
import Visualizar from "./pages/GestaoDeClientes/pages/VisualizarCliente";
import EditarCliente from "./pages/GestaoDeClientes/pages/EditarCliente";
import { SelectClientesProvider } from "./pages/AgendaCare/data/context/selectClientesContext";
import FormularioMedico from "./pages/AgendaCare/pages/FormularioMedico";
import VisualizarMedico from "./pages/AgendaCare/pages/VisualizarMedico";
import EditarMedico from "./pages/AgendaCare/pages/EditarMedico";
import GestaoDeContratos from "./pages/GestaoDeContratos";
import CalculadoraProdutividade from "./pages/CalculadoraProdutividade";

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(AuthContext);
  const location = useLocation();
  let url = location.pathname;

  if (loading) {
    return <Loading />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to={`/login${url}`} />;
  }

  if (authenticated && url.includes("/login")) {
    url = url.replaceAll("/login", "");
    return <Redirect to={url} />;
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute path="/" exact isPrivate>
        <Redirect to="home" />
      </CustomRoute>
      <CustomRoute path="/login/:url?">
        <Login />
      </CustomRoute>

      <CustomRoute path="/home" isPrivate>
        <Home />
      </CustomRoute>
      <CustomRoute path="/agendamentos" isPrivate>
        <Agendamento />
      </CustomRoute>
      <CustomRoute path="/coletaDomiciliar" isPrivate>
        <ColetaDomiciliar />
      </CustomRoute>
      <CustomRoute path="/mensagensApp" isPrivate>
        <MensagensApp />
      </CustomRoute>
      <CustomRoute path="/driveThru" isPrivate>
        <DriveThru />
      </CustomRoute>
      <CustomRoute path="/amostras" isPrivate>
        <Amostras />
      </CustomRoute>
      <CustomRoute path="/formularioCovid/cadastro" isPrivate>
        <CadastroFormulario />
      </CustomRoute>
      <CustomRoute path="/formularioCovid/" exact isPrivate>
        <ControleFormulario />
      </CustomRoute>
      <CustomRoute path="/respostasFormulario/:id" isPrivate>
        <RespostasFormulario />
      </CustomRoute>
      <CustomRoute path="/atualizacoesApp" isPrivate>
        <AtualizacoesApp />
      </CustomRoute>
      <CustomRoute path="/controleAcesso" isPrivate>
        <ControleAcesso />
      </CustomRoute>
      <CustomRoute path="/precosExames" isPrivate>
        <PrecosExames />
      </CustomRoute>
      <CustomRoute path="/agendaPaternidade" isPrivate exact>
        <AgendaPaternidade />
      </CustomRoute>
      <CustomRoute path="/espermograma" isPrivate exact>
        <Espermograma />
      </CustomRoute>
      <CustomRoute path="/faturamento" isPrivate>
        <Faturamento />
      </CustomRoute>
      <CustomRoute path="/portalMedico" isPrivate exact>
        <PortalMedico />
      </CustomRoute>
      <CustomRoute path="/portalMedico/editor/artigo/:id?" isPrivate exact>
        <EditorArtigoPortalMedico />
      </CustomRoute>
      <CustomRoute path="/calculadoraProdutividade" isPrivate exact>
        <CalculadoraProdutividade />
      </CustomRoute>
      <CustomRoute path="/portalMedico/editor/post/:id?" isPrivate exact>
        <EditorPostPortalMedico />
      </CustomRoute>
      <CustomRoute path="/mapaCurvas" isPrivate exact>
        <MapaCurvas />
      </CustomRoute>
      <CustomRoute path="/mapaCurvas/tv" isPrivate exact>
        <MapaCurvas />
      </CustomRoute>
      <CustomRoute path="/mapaCurvas/tv/:id?" isPrivate exact>
        <MapaCurvasTv />
      </CustomRoute>
      <CustomRoute path="/perfil" exact>
        <Perfil />
      </CustomRoute>
      <CustomRoute path="/CadastraUsuario" isPrivate exact>
        <CadastraUsuarioIndex />
      </CustomRoute>
      <CustomRoute path="/CadastraCategoria" isPrivate exact>
        <CadastrarCategoria />
      </CustomRoute>
      <CustomRoute path="/almoxarifado" isPrivate exact>
        <Almoxarifado />
      </CustomRoute>
      <CustomRoute path="/almoxarifado/produtos" isPrivate exact>
        <ExibeProdutos />
      </CustomRoute>
      <CustomRoute
        path="/almoxarifado/produtos/CadastraProduto"
        isPrivate
        exact
      >
        <CadastrarProduto />
      </CustomRoute>
      <CustomRoute path="/conexaoDNA" isPrivate exact>
        <ConexaoDNA />
      </CustomRoute>
      <CustomRoute path="/almoxarifado/estoque/adicionar" isPrivate exact>
        <AdicionarEstoque />
      </CustomRoute>
      <CustomRoute path="/almoxarifado/transferencia/criar" isPrivate exact>
        <CriarTransferencia />
      </CustomRoute>
      <CustomRoute path="/almoxarifado/transferencia/editar" isPrivate exact>
        <EditaTransferencia />
      </CustomRoute>
      <CustomRoute path="/respiratorio" isPrivate exact>
        <Respiratorio />
      </CustomRoute>
      <CustomRoute path="/almoxarifado/estoque/visualizar" isPrivate exact>
        <ProdutoInfo />
      </CustomRoute>
      <CustomRoute path="/financeiro/contratos" isPrivate exact>
        <GestaoDeContratos />
      </CustomRoute>
      <CustomRoute path="/financeiro/fornecedor" isPrivate exact>
        <Fornecedores />
      </CustomRoute>
      <CustomRoute path="/financeiro/contratos/criar" isPrivate exact>
        <FormContrato />
      </CustomRoute>
      <CustomRoute path="/financeiro/fornecedor/criar" isPrivate exact>
        <FormFornecedor />
      </CustomRoute>

      <CustomRoute path="/inventario/bem/cadastrarBem" isPrivate exact>
        <CadastrarBem />
      </CustomRoute>
      <CustomRoute path="/inventario/bem/criaTransferencia" isPrivate exact>
        <CriaTransferencia />
      </CustomRoute>
      <CustomRoute path="/inventario" isPrivate exact>
        <Inventario />
      </CustomRoute>

      <CustomRoute path="/inventario/criaInventario" isPrivate exact>
        <CriaInventario />
      </CustomRoute>
      <CustomRoute path="/inventario/editaInventario" isPrivate exact>
        <EditaInventario />
      </CustomRoute>
      <CustomRoute path="/inventario/inventarioBem" isPrivate exact>
        <InventarioBem />
      </CustomRoute>

      <CustomRoute path="/exames" isPrivate exact>
        <ProcurarExames />
      </CustomRoute>

      <CustomRoute path="/atendimentoAoCliente" isPrivate exact>
        <AtendimentoAoCliente />
      </CustomRoute>

      <CustomRoute
        path="/atendimentoAoCliente/formularioOuvidoria"
        isPrivate
        exact
      >
        <AdicionarOuvidoria />
      </CustomRoute>

      <CustomRoute path="/atendimentoAoCliente/formularioSAC" isPrivate exact>
        <AdicionarSac />
      </CustomRoute>

      <CustomRoute path="/centralDeBIs" isPrivate exact>
        <CentralDeBIs />
      </CustomRoute>

      <CustomRoute path="/centralDeBIs/BI" isPrivate exact>
        <BI />
      </CustomRoute>

      <CustomRoute path="/agendaCare" isPrivate exact>
        <AgendaCare />
      </CustomRoute>

      <CustomRoute path="/agendaGH" isPrivate exact>
        <AgendaGH />
      </CustomRoute>

      <CustomRoute path="/agendaCare/editarAgendamento" isPrivate exact>
        <EditarAgendamento />
      </CustomRoute>
      <CustomRoute path="/agendaCare/visualizarAgendamento" isPrivate exact>
        <VisualizarAgendamento />
      </CustomRoute>
      <CustomRoute path="/agendaCare/CadastrarMedico" isPrivate exact>
        <FormularioMedico />
      </CustomRoute>
      <CustomRoute path="/agendaCare/VisualizarMedico" isPrivate exact>
        <VisualizarMedico />
      </CustomRoute>
      <CustomRoute path="/agendaCare/EditarMedico" isPrivate exact>
        <EditarMedico />
      </CustomRoute>

      <CustomRoute path="/gestaoDeClientes" isPrivate exact>
        <GestaoDeClientes />
      </CustomRoute>

      <CustomRoute path="/gestaoDeClientes/visualizarCliente" isPrivate exact>
        <Visualizar />
      </CustomRoute>
      <CustomRoute path="/gestaoDeClientes/editarCliente" isPrivate exact>
        <EditarCliente />
      </CustomRoute>

      <SelectClientesProvider>
        {/* Provider para compartilhar informcoes sobre o cliente entre o forms de agendamento e o cadastro clientes */}
        <CustomRoute path="/agendaCare/formularioAgendamento" isPrivate exact>
          <FormularioAgendamento />
        </CustomRoute>
        <CustomRoute
          path="/gestaoDeClientes/cadastroDeClientes"
          isPrivate
          exact
        >
          <CadastroDeClientes />
        </CustomRoute>
      </SelectClientesProvider>

      <RotasAdministrativo />
    </Switch>
  );
}
