import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  Select as MuiSelect,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";

import { Context as PermissoesContext } from "../../../Context/PermissoesContext";
import { FormularioContext } from "../Context/FormularioContext";

import "./styles.css";
import Box from "../../Box";

const StyledTypographyWeek = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  padding: "0.5rem",
  "@media (max-width: 515px)": {
    fontSize: "1.1rem",
  },
}));

const StyledTypographyDay = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  padding: "0.5rem",
  "@media (max-width: 515px)": {
    fontSize: "1.8rem",
  },
}));

const StyledPaperTitle = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "100%",
  fontSize: "1.4rem",
  fontWeight: "bold",
  padding: "0.5rem",
  marginBottom: "1rem",
  justifyContent: "space-between",
}));

const StyledPaperCell = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "15%",
  minWidth: "15rem",
  height: "8rem",
  marginBottom: "1rem",
  alignContent: "center",
  "@media (max-width: 780px)": {
    width: "45%",
  },
  "@media (max-width: 515px)": {
    minWidth: "10rem",
    "@media (max-width: 450px)": {
      width: "100%",
    },
  },
}));

const StyledButtonAgenda = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "2rem",
  alignSelf: "flex-end",
}));

const StyledButtonSeta = styled(Button)(({ theme }) => ({
  height: "2rem",
}));

export default function Tabela(props) {
  const { checarPermissaoComAcao } = useContext(PermissoesContext);

  const { diasSemana, horarios, acesso, agendaAtual } = props;

  const unidades = [
    { id: 42, nome: "Lagoa Nova" },
    //{ id: 64, nome: "Seaway" },
  ];

  const [UnidadeAtual, setUnidadeAtual] = useState(42);

  const {
    agendamentos,
    setDia,
    mes,
    setMes,
    ano,
    setAno,
    setFinal,
    setAbreAgendamento,
    setTitulo,
    setDadosAgendamento,
    setHoraSelecionada,
    setUnidade,
  } = useContext(FormularioContext);

  const [mesSelecionado, setMesSelecionado] = useState("");
  const [vetorDias, setVetorDias] = useState([]);
  const mesExtenso = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const diaSemana = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];

  function getDiasMes() {
    const date = new Date(ano, mes, 1);
    const hoje = new Date();
    const days = [];

    let verificaInicioSemana = date.getDay();
    // Adiciona células vazias na tabela considerando o inicio desejado na segunda
    while (verificaInicioSemana > 1) {
      days.push({
        id: null,
        nome: null,
        id2: null,
        id3: null,
        nome2: null,
        nome3: null,
        dia: null,
        diaSemana: null,
        ativo: false,
      });
      verificaInicioSemana -= 1;
    }

    while (date.getMonth() === mes) {
      const dia = date.getDate();
      let ativo = false;
      const diaSemanaAtual = date.getDay();
      if (diasSemana.includes(diaSemanaAtual)) {
        if (hoje < date) {
          ativo = true;
        }

        days.push({
          id: null,
          nome: null,
          id2: null,
          id3: null,
          nome2: null,
          nome3: null,
          dia,
          diaSemana: diaSemana[diaSemanaAtual],
          ativo,
        });
      } else if (diaSemanaAtual !== 0) {
        days.push({
          id: null,
          nome: null,
          dia,
          diaSemana: diaSemana[diaSemanaAtual],
          ativo: false,
        });
      }

      date.setDate(date.getDate() + 1);
    }

    setFinal(days[days.length - 1].dia);

    while (days.length % 6 !== 0) {
      days.push({
        id: null,
        nome: null,
        id2: null,
        id3: null,
        nome2: null,
        nome3: null,
        diaSemana: null,
        ativo: false,
      });
    }

    return days;
  }

  useEffect(() => {
    if (agendaAtual === "respiratorio") {
      setUnidade(UnidadeAtual);
    }
  }, [UnidadeAtual, agendaAtual]);

  useEffect(() => {
    setVetorDias(getDiasMes());
    setMesSelecionado(mesExtenso[mes]);
    // eslint-disable-next-line
  }, [agendamentos, mes, ano]);

  useEffect(() => {
    function organizaAgendamentos() {
      agendamentos.map((a) => {
        const dia = a.data.slice(8, 10);

        setVetorDias((prevState) => {
          const agendados = prevState;
          const agenda = agendados.map((item) => {
            if (String(item.dia).padStart(2, "0") === dia) {
              if (agendaAtual === "respiratorio") {
                if (a.hora === horarios[0]) {
                  return { ...item, id: a.id, nome: a.nome };
                } else if (a.hora === horarios[1]) {
                  return { ...item, id3: a.id, nome3: a.nome };
                } else if (a.hora === horarios[2]) {
                  return { ...item, id2: a.id, nome2: a.nome };
                }
              } else {
                if (a.hora === horarios[0]) {
                  return { ...item, id: a.id, nome: a.nome };
                }
                return { ...item, id2: a.id, nome2: a.nome };
              }
            }
            return { ...item };
          });
          return agenda;
        });

        // const mesValido = mes + 1;
        // if (String(a.data) === `${ano}-${String(mesValido).padStart(2,'0')}-${String(dia).padStart(2,'0')}`) {
        //   id = a.id;
        //   nome = a.nome;
        // }
        return null;
      });
    }
    organizaAgendamentos();
  }, [horarios, agendamentos]);

  function alteraMes(modo) {
    setVetorDias([]);
    let mudaAno = false;
    setMes((prevState) => {
      let value = prevState;
      if (value === 11 && modo === "mais") {
        mudaAno = ano + 1;
        value = 0;
      } else if (value === 0 && modo === "menos") {
        mudaAno = ano - 1;
        value = 11;
      } else if (modo === "mais") {
        value += 1;
      } else {
        value -= 1;
      }
      return value;
    });
    if (mudaAno) {
      setAno(mudaAno);
    }
  }

  function agendaOuVisualiza(visualiza, dia, id, hora) {
    if (visualiza) {
      const dados = agendamentos.filter((a) => a.id === id);
      setTitulo("Informações do agendamento");
      setDadosAgendamento(dados[0]);
      setAbreAgendamento(true);
    } else {
      checarPermissaoComAcao(`${acesso}002`, () => {
        setTitulo("Agendamento");
        setDia(dia);
        setHoraSelecionada(hora);
        setAbreAgendamento(true);
      });
    }
  }

  return (
    <div id="container-calendario-agenda">
      <StyledPaperTitle>
        {agendaAtual === "respiratorio" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: 1 / 6,
              background: "white",
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Selecionar Unidade</InputLabel>
              <MuiSelect
                value={UnidadeAtual}
                onChange={(e) => setUnidadeAtual(Number(e.target.value))}
                label="Selecionar Unidade"
              >
                {unidades.map((unidade) => (
                  <MenuItem key={unidade.id} value={unidade.id}>
                    {unidade.nome}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Box>
        ) : null}
        <StyledButtonSeta
          onClick={() => alteraMes("menos")}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <i className="fa fa-long-arrow-left fa-2x" aria-hidden="true" />
        </StyledButtonSeta>
        <span
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {mesSelecionado.toUpperCase()} - {ano}
        </span>
        <StyledButtonSeta
          onClick={() => alteraMes("mais")}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <i className="fa fa-long-arrow-right fa-2x" aria-hidden="true" />
        </StyledButtonSeta>
      </StyledPaperTitle>
      {vetorDias.map((d) =>
        agendaAtual === "respiratorio" ? (
          <StyledPaperCell key={d.dia} elevation={3}>
            <div className="container-titulo-calendario-agenda">
              <StyledTypographyWeek variant="string">
                {d.diaSemana}
              </StyledTypographyWeek>
              <StyledTypographyDay variant="string">
                {d.dia}
              </StyledTypographyDay>
            </div>
            <div className="container-corpo-calendario-agenda">
              <div className="container-botoes-calendario-agenda">
                {(d.id || d.ativo) && (
                  <span
                    className={
                      d.id ? "nome-cliente-agendado" : "nome-cliente-livre"
                    }
                  >
                    {d.id ? d.nome.split(" ")[0] : horarios[0]}
                  </span>
                )}
                {agendaAtual === "respiratorio" &&
                  [
                    "Segunda-feira",
                    "Quarta-feira",
                    "Sexta-feira",
                    "Sábado",
                  ].includes(d.diaSemana) &&
                  (d.id3 || (d.ativo && horarios.length > 2)) && (
                    <span
                      className={
                        d.id3 ? "nome-cliente-agendado" : "nome-cliente-livre"
                      }
                    >
                      {d.id3 ? d.nome3.split(" ")[0] : horarios[1]}
                    </span>
                  )}
                {(d.id2 || (d.ativo && horarios.length > 1)) && (
                  <span
                    className={
                      d.id2 ? "nome-cliente-agendado" : "nome-cliente-livre"
                    }
                  >
                    {d.id2 ? d.nome2.split(" ")[0] : horarios[2]}
                  </span>
                )}
              </div>
              <div className="container-botoes-calendario-agenda">
                {(d.id || d.ativo) && (
                  <StyledButtonAgenda
                    onClick={() =>
                      agendaOuVisualiza(d.id, d.dia, d.id, horarios[0])
                    }
                  >
                    {!d.id ? "Agendar" : "Visualizar"}
                  </StyledButtonAgenda>
                )}
                {agendaAtual === "respiratorio" &&
                  [
                    "Segunda-feira",
                    "Quarta-feira",
                    "Sexta-feira",
                    "Sábado",
                  ].includes(d.diaSemana) &&
                  (d.id3 || (d.ativo && horarios.length > 2)) && (
                    <StyledButtonAgenda
                      onClick={() =>
                        agendaOuVisualiza(d.id3, d.dia, d.id3, horarios[1])
                      }
                    >
                      {d.id3 ? "Visualizar" : "Agendar"}
                    </StyledButtonAgenda>
                  )}
                {(d.id2 || (d.ativo && horarios.length > 1)) && (
                  <StyledButtonAgenda
                    onClick={() =>
                      agendaOuVisualiza(d.id2, d.dia, d.id2, horarios[2])
                    }
                  >
                    {d.id2 ? "Visualizar" : "Agendar"}
                  </StyledButtonAgenda>
                )}
              </div>
            </div>
          </StyledPaperCell>
        ) : (
          <StyledPaperCell key={d.dia} elevation={3}>
            <div className="container-titulo-calendario-agenda">
              <StyledTypographyWeek variant="string">
                {d.diaSemana}
              </StyledTypographyWeek>
              <StyledTypographyDay variant="string">
                {d.dia}
              </StyledTypographyDay>
            </div>
            <div className="container-corpo-calendario-agenda">
              <div className="container-botoes-calendario-agenda">
                {(d.id || d.ativo) && (
                  <span
                    className={
                      d.id ? "nome-cliente-agendado" : "nome-cliente-livre"
                    }
                  >
                    {d.id ? d.nome.split(" ")[0] : horarios[0]}
                  </span>
                )}
                {(d.id2 || (d.ativo && horarios.length > 1)) && (
                  <span
                    className={
                      d.id2 ? "nome-cliente-agendado" : "nome-cliente-livre"
                    }
                  >
                    {d.id2 ? d.nome2.split(" ")[0] : horarios[1]}
                  </span>
                )}
              </div>
              <div className="container-botoes-calendario-agenda">
                {(d.id || d.ativo) && (
                  <StyledButtonAgenda
                    onClick={() =>
                      agendaOuVisualiza(d.id, d.dia, d.id, horarios[0])
                    }
                  >
                    {!d.id ? "Agendar" : "Visualizar"}
                  </StyledButtonAgenda>
                )}
                {(d.id2 || (d.ativo && horarios.length > 1)) && (
                  <StyledButtonAgenda
                    onClick={() =>
                      agendaOuVisualiza(d.id2, d.dia, d.id2, horarios[1])
                    }
                  >
                    {d.id2 ? "Visualizar" : "Agendar"}
                  </StyledButtonAgenda>
                )}
              </div>
            </div>
          </StyledPaperCell>
        )
      )}
    </div>
  );
}
